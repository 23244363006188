import "./Thank.css";
import Navbar from "../../common/navbar/navbar";
import Banner from "../../common/Banner/Banner";
import Footer from "../../common/Footer/Footer";
import NavMain from "../../common/navbar/NavMain";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Thank = () => {
  useEffect(() => {
    document.title = "Thank You";
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Thanks for your enquiry. One of our staff member will contact you soon."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <NavMain />
      <Navbar />
      <Banner sublink="Thank-You" />
      <div className="thank">
        <h1>Thank you</h1>
        <h2>We have recieved your inquiry. We will contact you soon.</h2>
        <div className="buttons">
          <Link to="/">Home</Link>
          <Link to="/feedback">Feedback</Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Thank;
