import "./Contact.css";
import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const MyLazyComponent = React.lazy(() =>
  import("../../common/Contact/MyLazyComponent")
);

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("You're good!");

    try {
      await axios(
        "https://nodemailer.node.purenbrightcleaning.com.au/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          data: mailerState,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.data);
            console.log("Message Sent");
            navigate("/thank-you");
            setLoading(false);
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await axios(
        "https://rug-cleaning-service.onrender.com/sendEmail",
        // "https://sendgrid-m8wi.onrender.com/api/sendMail",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          data: mailerState,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("Message Sent");
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      await axios("https://purenbrightdatabase.onrender.com/createUser", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: mailerState,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.title = "Contact Us - Pure N Bright Cleaning";
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="We offer carpet cleaning, upholstery & leather cleaning, mattress cleaning, tile & grout cleaning at best prices in Melbourne. Get A Free Quote 1300 360 274"
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading...</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading...</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading...</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/contact-banner.jpg")}
          sublink={"Contact"}
          slogan="Connect with us right now"
        />
      </Suspense>
      <div className="contactMain">
        <div className="form-wrapper">
          <h2>
            <span>SEND US </span>A MESSAGE
          </h2>
          <form onSubmit={submitEmail} action="">
            <div className="name">
              <div className="block">
                <input
                  disabled={loading}
                  type="text"
                  placeholder="Name*"
                  required
                  name="name"
                  className="box"
                  value={mailerState.name}
                  onChange={handleStateChange}
                />
              </div>
            </div>

            <div className="name">
              <div className="block">
                <input
                  disabled={loading}
                  type="number"
                  placeholder="Phone Number*"
                  required
                  name="phone"
                  className="box"
                  value={mailerState.phone}
                  onChange={handleStateChange}
                />
              </div>
              <div className="block">
                <input
                  disabled={loading}
                  type="email"
                  placeholder="E-mail*"
                  name="email"
                  required
                  className="box"
                  value={mailerState.email}
                  onChange={handleStateChange}
                />
              </div>
            </div>

            <textarea
              disabled={loading}
              name="message"
              placeholder="Message..."
              className="box"
              id=""
              cols="30"
              rows="10"
              value={mailerState.message}
              onChange={handleStateChange}
            />

            <div
              style={{ transformOrigin: "0 0", width: "100%" }}
              className="captcha"
            >
              <Suspense fallback={<p>loading...</p>}>
                <MyLazyComponent />
              </Suspense>
            </div>

            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <button type="submit" className="submit" id="submit">
                <span className="submit">Submit</span>
                <img
                  className="submit"
                  src={require("../../../Assets/Images/Icons/enquire-blue.png")}
                  alt=""
                />
              </button>
            )}

            <Link to={"/feedback"}>Write a feedback to us...</Link>
          </form>
        </div>

        <div className="info-wrapper">
          <h2>CONTACT</h2>
          <p className="info-p">
            If you have questions, or would like more information, please leave
            your name and contact information.
          </p>
          <div className="info-box">
            <div className="icon">
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <div className="content">
              <span>ADDRESS</span>
              <p style={{ color: "gray" }}>
                6 Achieve Way, <br /> Pakenham Vic 3810, Australia
              </p>
            </div>
          </div>
          <div className="info-box">
            <div className="icon">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="content">
              <span>PHONE</span>
              <a href={"tel:1300360274"}>
                <p>1300 360 274</p>
              </a>
            </div>
          </div>
          <div className="info-box">
            <div className="icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="content">
              <span>E-MAIL</span>
              <a href={"mailto:info@purenbrightcleaning.com.au"}>
                <p>info@purenbrightcleaning.com.au</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="melborne">
        <h1>Serving All Melbourne Suburbs</h1>
        <img
          src={require("../../../Assets/Images/Banner/Melbourne.webp")}
          alt=""
        />
      </div>
      <Suspense fallback={<p>loading...</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Contact;
