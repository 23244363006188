import { Helmet } from "react-helmet";
import React, { Suspense, useEffect } from "react";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const GallerySubComp = React.lazy(() => import("./GallerySubComp"));

const CarpetGallery = () => {
  useEffect(() => {
    document.title = "Carpet Cleaning Gallery Album - Pure N Bright Cleaning";
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Carpet Cleaning Gallery - Checkout Our Recent Before / After Carpet Cleaning Work."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading..</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/gallery-sub-banner.jpg")}
          sublink="Gallery"
          sublink2="Carpet Cleaning Gallery"
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <GallerySubComp
          title="Carpet"
          images={[
            {
              src: require("../../../Assets/Images/Gallery/Carpet/1.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/2.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/3.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/4.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/5.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/6.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/7.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/8.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/9.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/10.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/11.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/12.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/13.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/14.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/15.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/16.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/17.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/18.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/19.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/20.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/21.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/22.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/23.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/24.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/25.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/26.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/27.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/28.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/29.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/30.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/31.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/32.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/33.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/34.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/35.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Carpet/36.jpg"),
            },
          ]}
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default CarpetGallery;
