import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../../common/Footer/Footer";
import Navbar from "../../../common/navbar/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faClipboardCheck,
  faFaceSmile,
  faHouse,
  faStar,
  faStopwatch,
  faTag,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import NavMain from "../../../common/navbar/NavMain";
import { Helmet } from "react-helmet";
import Quote from "../../../common/Contact/Quote";
import LandingContact from "../../../common/Contact/LandingContact";

function DryCarpet(props) {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="We are professional rug & carpet cleaning company based in Melbourne - We offer cheap dry & steam carpet cleaning services in Pakenham & other Melbourne suburbs. Same day carpet cleaning services, Call 1300 360 274."
        />
        <meta
          name="keywords"
          content="rug cleaning melbourne, professional rug cleaning near me, rug cleaning near me, carpet cleaning near me melbourne"
        />
      </Helmet>
      <NavMain />
      <Navbar />
      <div
        className="homeL"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(85, 85, 85, 0.5), transparent), url(${require("../../../../Assets/Images/Banner/wet-banner-3.jpg")})`,
          //   backgroundImage: `linear-gradient(90deg, rgba(85, 85, 85, 0.5), transparent), url(${require("../../../../Assets/Images/Banner/Upholstery-banner.jpeg")})`,
          // backgroundPosition: "center",
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
        }}
      >
        <div className="main_banner">
          <div className="main_banner_content">
            <h1>
              Got wet carpets? <br />
              We can dry them on the same day.
            </h1>
            <Link to={"/contact-us"} className="rug-contact">
              <span>Enquire Now </span>

              <img
                src={require("../../../../Assets/Images/Icons/enquire-blue.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="quote">
            <Quote />
          </div>
        </div>

        <LandingContact wet={true} cleaning={"dry"} />

        <div className="wet-page">
          <div className="heading">
            <h2>Drying the Wet Carpets.</h2>
          </div>
          <div className="content">
            <div className="img">
              <img
                src={require("../../../../Assets/Images/Wet/wet-1.jpeg")}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="info">
              <p>
                Got wet carpets? We can dry them on the same day. Call now
                before they start getting mouldy and smell bad! Free Quotes.
              </p>
              <img
                src={require("../../../../Assets/Images/Wet/wet-2.jpeg")}
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="choose_us section">
          <div className="choose_us heading">
            <h2>Why Choose Us?</h2>
          </div>
          <div className="choose_us content">
            <div className="choose_us info">
              <ul>
                <li>
                  {/* <img
                    src={require("../../../../Assets/Images/Icons/sheild.png")}
                    alt=""
                  />{" "} */}
                  <FontAwesomeIcon icon={faStopwatch} />
                  Quick Response Time
                </li>
                <li>
                  {/* <img
                    src={require("../../../../Assets/Images/Icons/check.png")}
                    alt=""
                  />{" "} */}
                  <FontAwesomeIcon icon={faHouse} />
                  Residentail & Commercial Services
                </li>
                <li>
                  {/* <img
                    src={require("../../../../Assets/Images/Icons/dollar.png")}
                    alt=""
                  />{" "} */}
                  <FontAwesomeIcon icon={faUserTie} />
                  Your Local Experts
                </li>
              </ul>
              <ul>
                <li>
                  {/* <img
                    src={require("../../../../Assets/Images/Icons/sanitization.png")}
                    alt=""
                  />{" "} */}
                  <FontAwesomeIcon icon={faClipboardCheck} />
                  Professional Solutions
                </li>
                <li>
                  {/* <img
                    src={require("../../../../Assets/Images/Icons/health.png")}
                    alt=""
                  />{" "} */}
                  <FontAwesomeIcon icon={faTag} />
                  Competitive Pricing
                </li>
                <li>
                  {/* <img
                    src={require("../../../../Assets/Images/Icons/plant.png")}
                    alt=""
                  />{" "} */}
                  <FontAwesomeIcon icon={faFaceSmile} />
                  Satisfaction Guaranteed
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="work section">
          <div className="work heading">
            <h2>Check Out Our Recent</h2>
          </div>
          <div className="work content">
            {" "}
            <section className="worksD">
              <h1 className="heading">Before & After Carpet Cleaning Work</h1>
              <div className="works-content">
                <img
                  src={require("../../../../Assets/Images/Gallery/Carpet/11.jpg")}
                  alt=""
                />
                <img
                  src={require("../../../../Assets/Images/Gallery/Carpet/35.jpg")}
                  alt=""
                />
                <img
                  src={require("../../../../Assets/Images/Gallery/Carpet/36.jpg")}
                  alt=""
                />
              </div>
              <Link to={"/gallery"}>
                <div className="more-images">
                  <h1>FOR MORE IMAGES CHECK OUT OUR GALLERY</h1>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </Link>
            </section>
          </div>
        </div>

        <div className="review section">
          <section className="reviews">
            <div className="heading">
              <h1> WHAT OUR CUSTOMERS HAVE TO SAY </h1>
            </div>
            <div className="review-content">
              <div className="content-box">
                <p>
                  Carpet came up like new which was better than I expected as it
                  was well used. The whole process was very easy with the pick
                  up and drop off service.
                </p>
                <span>Cassie</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
              <div className="content-box">
                <p>
                  I had my carpet cleaned and was very pleased with the service.
                  The pickup and drop off option were a lifesaver and the carpet
                  came back beautifully clean and odour-free. Will definitely
                  use again.
                </p>
                <span>Phoebe</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
              <div className="content-box">
                <p>
                  Just had my carpet cleaned and the result is fantastic. Wool{" "}
                  carpet with pet stains couldn’t stand it another minute. Now
                  it looks and feels great. Service was great too.
                </p>
                <span>Afinial</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
            </div>
            <Link
              to={"https://www.productreview.com.au/listings/pure-n-bright"}
            >
              <div className="more-review">
                <span>For More Reviews Click Here</span>
              </div>
            </Link>
          </section>
        </div>

        <div className="extra">
          <div className="extra1">
            <img
              src={require("../../../../Assets/Images/Icons/satisfaction.png")}
              alt=""
            />
            <h2>Satisfaction Guarantee</h2>
            <p>
              We are passionate about carpets and work hard to beat all our
              customers' expectations. Let our experts clean your carpets and
              provide the shine they deserve.
            </p>
          </div>
        </div>

        <div className="frequency-wrapper">
          <div className="frequency">
            <h1>Frequency Of Carpet Cleaning</h1>
            <p>
              The carpet cleaning needs to be done at regular intervals in order
              to enhance the life and appearance of the carpet.
            </p>
            <div className="frequency-content">
              <div className="frequency-box">
                <img
                  src={require("../../../../Assets/Images/Frequency/frequency1.png")}
                  alt=""
                />
                <h2>6 TO 8 MONTHS</h2>
              </div>
              <div className="frequency-box">
                <img
                  src={require("../../../../Assets/Images/Frequency/frequency2.png")}
                  alt=""
                />
                <h2>8 TO 10 MONTHS</h2>
              </div>
              <div className="frequency-box">
                <img
                  src={require("../../../../Assets/Images/Frequency/frequency3.png")}
                  alt=""
                />
                <h2>12 MONTHS</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="protect section">
          <div className="protect heading"></div>
          <div className="protect content">
            <div className="protect info">
              <h2>Protect</h2>
              <h3>Your Valuable Carpet</h3>
              <p>
                After cleaning, we recommend applying stains protectors to avoid
                further staining and to stay carpets cleaner for a longer
                period.
              </p>
            </div>
            <div className="protect img">
              <img
                src={require("../../../../Assets/Images/Pets/pet.webp")}
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default DryCarpet;
