import React, { Suspense, useState } from "react";
import "./ContactComponent.css";
import "../../pages/Home/Home.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const MyLazyComponent = React.lazy(() => import("./MyLazyComponent"));

const ContactComp = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("You're good!");

    try {
      await axios(
        "https://nodemailer.node.purenbrightcleaning.com.au/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          data: mailerState,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.data);
            console.log("Message Sent");
            navigate("/thank-you");
            setLoading(false);
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await axios(
        "https://rug-cleaning-service.onrender.com/sendEmail",
        // "https://sendgrid-m8wi.onrender.com/api/sendMail",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          data: mailerState,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("Message Sent");
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      await axios("https://purenbrightdatabase.onrender.com/createUser", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: mailerState,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <>
      <div className="contact section">
        <div className="contact content">
          <div className="contact img">
            <img
              src={require("../../../Assets/Images/Icons/contact-avatar.webp")}
              alt=""
              loading="lazy"
            />
          </div>
          <div className="contact info">
            <section className="contact1" id="contact">
              <h2>We're Here To Help...</h2>
              <h1 className="heading"> Contact Us </h1>
              <form onSubmit={submitEmail} action="">
                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="text"
                      placeholder="Name*"
                      name="name"
                      required
                      className="box"
                      value={mailerState.name}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="number"
                      placeholder="Phone Number*"
                      name="phone"
                      className="box"
                      required
                      value={mailerState.phone}
                      onChange={handleStateChange}
                    />
                  </div>
                  <div className="block">
                    <input
                      disabled={loading}
                      type="email"
                      placeholder="E-mail*"
                      name="email"
                      required
                      className="box"
                      value={mailerState.email}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <textarea
                  disabled={loading}
                  name="message"
                  placeholder="Message..."
                  className="box"
                  id=""
                  cols="30"
                  rows="10"
                  value={mailerState.message}
                  onChange={handleStateChange}
                />

                <div
                  style={{ transformOrigin: "0 0", width: "100%" }}
                  className="captcha"
                >
                  <Suspense fallback={<p>Captcha is loading...</p>}>
                    <MyLazyComponent />
                  </Suspense>
                </div>

                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <button type="submit" className="submit" id="submit">
                    <span className="submit">Submit</span>
                    <img
                      className="submit"
                      src={require("../../../Assets/Images/Icons/enquire-blue.png")}
                      alt=""
                    />
                  </button>
                )}
                <Link to="/feedback">Write a feedback to us...</Link>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactComp;
