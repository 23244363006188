import "./Deep.css";
import React, { Suspense } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import NavMain from "../../../common/navbar/NavMain";
import Navbar from "../../../common/navbar/navbar";
import Quote from "../../../common/Contact/Quote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faStar } from "@fortawesome/free-solid-svg-icons";
import FabricLanding from "../../../common/Contact/FabricLanding";
const Footer = React.lazy(() => import("../../../common/Footer/Footer"));

function LeatherFabric(props) {
  useEffect(() => {
    document.title =
      "Professional Carpet & Rug Cleaning Melbourne | Hire Today - purenbrightcleaning.com.au";
  }, []);

  const { pathname } = useLocation();

  let CR = pathname.split("-")[0];
  let cleaning = pathname.split("-")[1];
  console.log(CR);
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="We are professional rug & carpet cleaning company based in Melbourne - We offer cheap dry & steam carpet cleaning services in Pakenham & other Melbourne suburbs. Same day carpet cleaning services, Call 1300 360 274."
        />
        <meta
          name="keywords"
          content="rug cleaning melbourne, professional rug cleaning near me, rug cleaning near me, carpet cleaning near me melbourne"
        />
      </Helmet>
      <Suspense fallback={<div>Loading..</div>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<div>Loading..</div>}>
        <Navbar />
      </Suspense>
      <div
        className="homeL"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(85, 85, 85, 0.5), transparent), url(${require("../../../../Assets/Images/Banner/leather-couch.webp")})`,
        }}
      >
        <div className="main_banner">
          <div className="main_banner_content">
            <h1>
              PROFESSIONAL <br />{" "}
              <span className="title"> LEATHER COUCH CLEANING</span> SERVICE{" "}
              <br />
              MELBOURNE (ALL SUBURBS)
            </h1>

            <Link to={"/contact-us"} className="rug-contact">
              <span>Enquire Now </span>

              <img
                src={require("../../../../Assets/Images/Icons/enquire-blue.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="quote">
            <Suspense fallback={<div>Loading..</div>}>
              <Quote />
            </Suspense>
          </div>
        </div>

        <Suspense fallback={<div>Loading..</div>}>
          <FabricLanding cleaning={cleaning} CR={CR} />
        </Suspense>

        <div className="tube section">
          <h2>We Clean all types of Couches</h2>
          <img
            src={require("../../../../Assets/Images/Banner/All-types-couch.jpeg")}
            alt=""
          />
        </div>

        <div className="couch-vid">
          <div className="tubeN">
            <h2>Our Couch Cleaning Process</h2>
            <iframe
              title="Youtube"
              srcdoc="
          <style>
              body, .full {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  position: absolute;
                  display: flex;
                  justify-content: center;
                  object-fit: cover;
              }
          </style>
          <a
              href='https://www.youtube.com/embed/2DmmdtNn7LI?si=2X81YAf7x7qvO_Vl&amp;controls=0'
              class='full'
          >
              <img
                  src='https://www.purenbrightcleaning.com.au/static/media/new-c.b15133b796cd3fdfd6bb.jpg'
                  class='full'
              />
              <svg
                  version='1.1'
                  viewBox='0 0 68 48'
                  width='68px'
                  style='position: relative;'
              >
                  <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                  <path d='M 45,24 27,14 27,34' fill='#fff'></path>
              </svg>
          </a>
      "
              frameborder="0"
            ></iframe>
          </div>
        </div>

        <div className="choose_us section">
          <div className="choose_us heading">
            <h2>Why Choose Us?</h2>
          </div>
          <div className="choose_us content">
            <div className="choose_us info">
              <ul>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/sheild.png")}
                    alt=""
                  />{" "}
                  DAMAGE FREE SERVICE
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/check.png")}
                    alt=""
                  />{" "}
                  QUALITY SERVICES
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/dollar.png")}
                    alt=""
                  />{" "}
                  ECONOMICAL
                </li>
              </ul>
              <ul>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/sanitization.png")}
                    alt=""
                  />{" "}
                  SANITIZATION & DISINFECTION
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/health.png")}
                    alt=""
                  />{" "}
                  HEALTHY ENVIRONMENT
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/plant.png")}
                    alt=""
                  />{" "}
                  NEW LOOK
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="work section">
          <div className="work heading">
            <h2>Check Out Our Recent</h2>
          </div>
          <div className="work content">
            {" "}
            <section className="worksD">
              <h1 className="heading">Before & After Couch Cleaning Work</h1>
              <div className="works-content">
                <img
                  src={require("../../../../Assets/Images/Gallery/Leather/10.jpg")}
                  alt=""
                />
                <img
                  src={require("../../../../Assets/Images/Gallery/Leather/11.jpg")}
                  alt=""
                />
                <img
                  src={require("../../../../Assets/Images/Gallery/Leather/12.jpg")}
                  alt=""
                />
              </div>
              <Link to={"/gallery/leather-cleaning-gallery"}>
                <div className="more-images">
                  <h1>FOR MORE IMAGES CHECK OUT OUR GALLERY</h1>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </Link>
            </section>
          </div>
        </div>

        <div className="review section">
          <section className="reviews">
            <div className="heading">
              <h1> WHAT OUR CUSTOMERS HAVE TO SAY </h1>
            </div>
            <div className="review-content">
              <div className="content-box">
                <p>
                  Couch came up like new which was better than I expected as it
                  was well used. The whole process was very easy with the pick
                  up and drop off service.
                </p>
                <span>Cassie</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
              <div className="content-box">
                <p>
                  I had my couch cleaned and was very pleased with the service.
                  The pickup and drop off option were a lifesaver and the couch
                  came back beautifully clean and odour-free. Will definitely
                  use again.
                </p>
                <span>Phoebe</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
              <div className="content-box">
                <p>
                  Just had my couch cleaned and the result is fantastic. Wool
                  couch with pet stains couldn’t stand it another minute. Now it
                  looks and feels great. Service was great too.
                </p>
                <span>Afinial</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
            </div>
            <Link
              to={"https://www.productreview.com.au/listings/pure-n-bright"}
            >
              <div className="more-review">
                <span>For More Reviews Click Here</span>
              </div>
            </Link>
          </section>
        </div>

        <div className="extra">
          <div className="extra1">
            <img
              src={require("../../../../Assets/Images/Icons/satisfaction.png")}
              alt=""
            />
            <h2>Satisfaction Guarantee</h2>
            <p>
              We are passionate about couch's and work hard to beat all our
              customers' expectations. Let our experts clean your couch's and
              provide the shine they deserve.{" "}
            </p>
          </div>
        </div>

        <div className="frequency-wrapper">
          <div className="frequency">
            <h1>Frequency Of Couch Cleaning</h1>
            <p>
              The couch cleaning needs to be done at regular intervals in order
              to enhance the life and appearance of the couch.
            </p>
            <div className="frequency-content">
              <div className="frequency-box">
                <img
                  src={require("../../../../Assets/Images/Frequency/frequency1.png")}
                  alt=""
                />
                <h2>6 TO 8 MONTHS</h2>
              </div>
              <div className="frequency-box">
                <img
                  src={require("../../../../Assets/Images/Frequency/frequency2.png")}
                  alt=""
                />
                <h2>8 TO 10 MONTHS</h2>
              </div>
              <div className="frequency-box">
                <img
                  src={require("../../../../Assets/Images/Frequency/frequency3.png")}
                  alt=""
                />
                <h2>12 MONTHS</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="protect section">
          <div className="protect heading"></div>
          <div className="protect content">
            <div className="protect info">
              <h2>Protect</h2>
              <h3>Your Valuable Couch</h3>
              <p>
                After cleaning, we recommend applying stains protectors to avoid
                further staining and to stay couches cleaner for a longer
                period.
              </p>
            </div>
            <div className="protect img">
              <img
                src={require("../../../../Assets/Images/Pets/dog-c.jpg")}
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <Suspense fallback={<div>Loading..</div>}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
}

export default LeatherFabric;
