import { Suspense, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faArrowRight,
  faBars,
  faEnvelope,
  faMultiply,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../Assets/Images/Logo/Pure-N-Bright-Logo.png.webp";
import "./NavMain.css";
import React from "react";
// import Disclaimar from "../Disclaimar/Disclaimar";
const SubBars = React.lazy(() => import("./SubBars"));

const NavMain = (props) => {
  const [meanNav, setMeanNav] = useState(false);
  const [services, setServices] = useState(false);
  // const [disPop, setDisPop] = useState(false);

  const path = useLocation().pathname;

  return (
    <>
      <div
        className={"navbarM"}
        style={{ background: props.bgc || "rgba(255, 255, 255, 0.9)" }}
      >
        {/* <div id="scroll-container">
          <div id="scroll-text">
            Our phone system is down at the moment. Please contact us via email{" "}
            <Link to={"mailto:info@purenbrightcleaning.com.au"}>
              info@purenbrightcleaning.com.au
            </Link>
          </div>
        </div> */}
        <div className="nav-tab">
          <div className="navbar_wrapper">
            <div className="navbar_logo">
              <Link to={"/"}>
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className="nav-content">
              <div className="nav-content-u">
                <Link to={"/contact-us"} className="nav-icon-wrapper">
                  <div className="nav-icon">
                    <Link>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Link>
                  </div>
                  <span>Contact us</span>
                </Link>
                <a
                  href={"tel:1300360274"}
                  // onClick={() => setDisPop(true)}
                  className="nav-icon-wrapper"
                >
                  <div className="nav-icon">
                    <FontAwesomeIcon id="phIcon" icon={faPhone} />
                  </div>
                  <span id="phno">1300 360 274</span>
                </a>
                <Link to={"/contact-us"}>
                  <button>Enquire Now</button>
                </Link>
                <FontAwesomeIcon
                  onClick={() => setMeanNav(!meanNav)}
                  icon={meanNav ? faMultiply : faBars}
                />
              </div>
              <div className="nav-content-d">
                <ul>
                  <Link
                    to={"/"}
                    className={path === "/" ? "link active" : "link"}
                  >
                    <li>HOME</li>
                  </Link>
                  <Suspense fallback={<p>loading...</p>}>
                    <SubBars
                      title="Services"
                      titlePath="/services"
                      height={14 + "rem"}
                      opt={[
                        {
                          name: "Carpet Cleaning",
                          link: "/services/carpet-cleaning-melbourne",
                        },
                        {
                          name: "Rug Cleaning",
                          link: "/services/rug-cleaning-melbourne",
                        },
                        {
                          name: "Upholstery Cleaning",
                          link: "/services/upholstery-cleaning-melbourne",
                        },
                        {
                          name: "Pressure Cleaning",
                          link: "/pressure-cleaning",
                        },
                      ]}
                    />
                  </Suspense>

                  <Link
                    to={"/ndis-house-cleaning-australia"}
                    className={
                      path === "/ndis-house-cleaning-australia"
                        ? "link active"
                        : "link"
                    }
                  >
                    <li>NDIS House Cleaning</li>
                  </Link>

                  <Link
                    to={"/gallery"}
                    className={path === "/gallery" ? "link active" : "link"}
                  >
                    <li>GALLERY</li>
                  </Link>

                  <Link
                    to={"/why-us"}
                    className={path === "/gallery" ? "link active" : "link"}
                  >
                    <li>WHY US?</li>
                  </Link>

                  <Link
                    to={"/blog"}
                    className={path === "/gallery" ? "link active" : "link"}
                  >
                    <li>BLOG</li>
                  </Link>

                  <Link
                    to={"/contact-us"}
                    className={path === "/contact-us" ? "link active" : "link"}
                  >
                    <li>CONTACT US</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
          <div className="MeanNav">
            <ul className={meanNav ? "mean-nav-list active" : "mean-nav-list"}>
              <Link to={"/"} className={path === "/" ? "link active" : "link"}>
                <li>
                  <FontAwesomeIcon className="rightIcon" icon={faArrowRight} />{" "}
                  HOME
                </li>
              </Link>
              <Link
                to={"/services"}
                className={path === "/services" ? "link active" : "link"}
              >
                <li className="plus">
                  <div>
                    <FontAwesomeIcon
                      style={{ marginRight: "5px" }}
                      className="rightIcon"
                      icon={faArrowRight}
                    />
                    SERVICES
                  </div>
                  <Link to={"#"}>
                    {!services ? (
                      <FontAwesomeIcon
                        onClick={() => setServices(!services)}
                        className="plusicon"
                        icon={faAngleRight}
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => setServices(!services)}
                        className="plusicon"
                        icon={faAngleDown}
                      />
                    )}
                  </Link>
                </li>
                <ul className={services ? "sub-mean servi" : "sub-mean"}>
                  <Link
                    to={"/services/carpet-cleaning-melbourne"}
                    className={
                      path === "/services/carpet-cleaning-melbourne"
                        ? "link active"
                        : "link"
                    }
                  >
                    <li>
                      <FontAwesomeIcon
                        className="rightIcon"
                        icon={faArrowRight}
                      />{" "}
                      Carpet Cleaning
                    </li>
                  </Link>
                  <Link
                    to={"/services/rug-cleaning-melbourne"}
                    className={
                      path === "/services/rug-cleaning-melbourne"
                        ? "link active"
                        : "link"
                    }
                  >
                    <li>
                      <FontAwesomeIcon
                        className="rightIcon"
                        icon={faArrowRight}
                      />{" "}
                      Rug Cleaning
                    </li>
                  </Link>
                  <Link
                    to={"/services/upholstery-cleaning-melbourne"}
                    className={
                      path === "/services/upholstery-cleaning-melbourne"
                        ? "link active"
                        : "link"
                    }
                  >
                    <li>
                      <FontAwesomeIcon
                        className="rightIcon"
                        icon={faArrowRight}
                      />{" "}
                      Upholstery Cleaning
                    </li>
                  </Link>
                  <Link
                    to={"/pressure-cleaning"}
                    className={
                      path === "/pressure-cleaning" ? "link active" : "link"
                    }
                  >
                    <li>
                      <FontAwesomeIcon
                        className="rightIcon"
                        icon={faArrowRight}
                      />{" "}
                      Pressure Cleaning
                    </li>
                  </Link>
                </ul>
              </Link>
              <Link
                to={"/ndis-house-cleaning-australia"}
                className={
                  path === "/ndis-house-cleaning-australia"
                    ? "link active"
                    : "link"
                }
              >
                <li>
                  <FontAwesomeIcon className="rightIcon" icon={faArrowRight} />{" "}
                  NDIS House Cleaning
                </li>
              </Link>
              <Link
                to={"/gallery"}
                className={path === "/gallery" ? "link active" : "link"}
              >
                <li>
                  <FontAwesomeIcon className="rightIcon" icon={faArrowRight} />{" "}
                  GALLERY
                </li>
              </Link>

              <Link
                to={"/why-us"}
                className={path === "/why-us" ? "link active" : "link"}
              >
                <li>
                  <FontAwesomeIcon className="rightIcon" icon={faArrowRight} />{" "}
                  WHY US?
                </li>
              </Link>

              <Link
                to={"/blog"}
                className={path === "/blog" ? "link active" : "link"}
              >
                <li>
                  <FontAwesomeIcon className="rightIcon" icon={faArrowRight} />{" "}
                  BLOG
                </li>
              </Link>

              <Link
                to={"/contact-us"}
                className={path === "/contact-us" ? "link active" : "link"}
              >
                <li>
                  <FontAwesomeIcon
                    style={{ paddingRight: "1rem" }}
                    icon={faArrowRight}
                  />{" "}
                  CONTACT US
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      {/* {disPop ? <Disclaimar setPop={setDisPop} /> : null} */}
    </>
  );
};

export default NavMain;
