import { Helmet } from "react-helmet";
import React, { Suspense, useEffect } from "react";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const GallerySubComp = React.lazy(() => import("./GallerySubComp"));

const MattressGallery = () => {
  useEffect(() => {
    document.title = "Mattress Cleaning Gallery Album - Pure N Bright Cleaning";
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Mattress Cleaning Gallery Album- Checkout Our Recent Before / After Carpet Cleaning Work."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading..</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/gallery-sub-banner.jpg")}
          sublink="Gallery"
          sublink2="Mattress Cleaning Gallery"
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <GallerySubComp
          title="Mattress"
          images={[
            {
              src: require("../../../Assets/Images/Gallery/Mattress/1.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/2.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/3.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/4.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/5.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/6.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/7.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/8.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/9.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Mattress/10.jpg"),
            },
          ]}
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default MattressGallery;
