import React from "react";
// import { hydrateRoot } from "react-dom/client";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// const container = document.getElementById("root");
// hydrateRoot(document.getElementById("root"), <App />);
