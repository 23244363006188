import React, { Suspense, useState } from "react";
import "./LandingContact.css";
import "../../pages/Home/Home.css";
import "../../pages/LandingPages/DryCarpet/DryCarprt.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const MyLazyComponent = React.lazy(() => import("./MyLazyComponent"));

const LandingContact = ({ cleaning, CR, wet }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("You're good!");

    try {
      await axios(
        "https://nodemailer.node.purenbrightcleaning.com.au/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          data: mailerState,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.data);
            console.log("Message Sent");
            navigate("/thank-you");
            setLoading(false);
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await axios("https://rug-cleaning-service.onrender.com/sendEmail", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: mailerState,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("Message Sent");
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      await axios("https://purenbrightdatabase.onrender.com/createUser", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: mailerState,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <>
      <div className={wet ? "contactL section wet" : "contactL section"}>
        <div className="contact content">
          {!wet ? (
            <div className="contact img">
              <div className="types info">
                <h2>
                  {wet
                    ? "Got wet carpets? We can dry them on the same day."
                    : cleaning === "carpet"
                    ? "Get Help For All Your Carpet & Upholstery Cleaning Needs"
                    : CR === "/rug"
                    ? "We Clean All Types of Rugs"
                    : "Get Help For All Your Carpet Cleaning Needs"}
                </h2>
                <div id="highlight"></div>
                <img
                  src={
                    wet
                      ? require("../../../Assets/Images/Wet/wet-2.jpeg")
                      : cleaning === "carpet"
                      ? require("../../../Assets/Images/Types/Carpet.png")
                      : cleaning === "dry"
                      ? require("../../../Assets/Images/Banner/Dry-feature.jpeg")
                      : CR === "/rug"
                      ? require("../../../Assets/Images/Types/all types.webp")
                      : require("../../../Assets/Images/Types/Flee Treatment.png")
                  }
                  alt=""
                  loading="lazy"
                />
                <p>
                  {cleaning === "carpet"
                    ? "We spend a lot of time on our couches, recliners and chairs. Get your carpet & upholstery cleaned on regular basis so you can stay healthy and germ free."
                    : CR === "/rug"
                    ? "Wool | Jute | Turkish | Viscouse | Art Silk | Silk | Moroccan | Sheep Skin | Cow Hide | Dhurrie | Silk | Oriental | Persian | Antique | Indian Rugs & Many More…"
                    : "We understand how much you love your carpet so as we, our fully trained technicians bring your carpet back to life."}
                </p>
              </div>
            </div>
          ) : null}
          {!wet ? (
            <div className="tubeQ">
              <h2>
                Our{" "}
                {cleaning === "deep"
                  ? "Deep"
                  : cleaning === "dry"
                  ? "Dry"
                  : cleaning === "steam"
                  ? "Steam"
                  : CR === "/carpet"
                  ? "Carpet"
                  : "Upholstery"}{" "}
                Cleaning Process
              </h2>
              {/* {cleaning === "carpet" ? ( */}
              {CR === "/carpet" ? (
                <img
                  src={
                    cleaning === "dry"
                      ? require("../../../Assets/Images/Banner/Dry-Cleaning.jpeg")
                      : require("../../../Assets/Images/Banner/steam-cleaning.jpeg")
                  }
                  alt=""
                />
              ) : CR === "/services/carpet" ? (
                <img
                  src={require("../../../Assets/Images/Banner/Carpet - Dry and Steam.png")}
                  alt=""
                />
              ) : (
                <iframe
                  src={
                    cleaning === "carpet"
                      ? "https://www.youtube.com/embed/2DmmdtNn7LI?si=loKBzUvA0_CX_tHn"
                      : CR === "/rug"
                      ? "https://www.youtube.com/embed/4I0ciiJDR20?si=zVPWQhcGie678Lcf&amp;controls=0"
                      : "https://www.youtube.com/embed/AcsBKRKTIsk?si=k788gn4dcnWVGHoS"
                  }
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  loading="lazy"
                ></iframe>
              )}
            </div>
          ) : null}
          <div className={wet ? "contact info wet" : "contact info"}>
            <section
              className="contact1"
              id="contact"
              style={{ width: "100%" }}
            >
              <h2>Free Quote</h2>
              <form onSubmit={submitEmail} action="">
                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="text"
                      placeholder="Name*"
                      name="name"
                      className="box"
                      value={mailerState.name}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="number"
                      placeholder="Phone Number*"
                      name="phone"
                      className="box"
                      value={mailerState.phone}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <div className="name">
                  <div className="block">
                    <input
                      disabled={loading}
                      type="email"
                      placeholder="E-mail*"
                      name="email"
                      className="box"
                      value={mailerState.email}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>

                <textarea
                  disabled={loading}
                  name="message"
                  placeholder="Message..."
                  className="box"
                  id=""
                  cols="30"
                  rows="10"
                  value={mailerState.message}
                  onChange={handleStateChange}
                />

                <div
                  style={{ transformOrigin: "0 0", width: "100%" }}
                  className="captcha"
                >
                  <Suspense fallback={<p>Captcha is loading...</p>}>
                    <MyLazyComponent />
                  </Suspense>
                </div>

                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <button type="submit" className="submit" id="submit">
                    <span className="submit">Submit</span>
                    <img
                      className="submit"
                      src={require("../../../Assets/Images/Icons/enquire-blue.png")}
                      alt=""
                    />
                  </button>
                )}
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingContact;
